import * as actionType from '../actions/ActionType';


const reducerKlines = (state={}, action) => {

    let newState;
    switch (action.type) {

        case actionType.UPDATE_KLINES:
        
            newState = action.msg
            return newState
        default:
            return state;
    }
}

export default reducerKlines;
