import React, { Component } from 'react';
import {  BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
//import { withStyles } from '@material-ui/core/styles';
//import CssBaseline from "@material-ui/core/CssBaseline";
//import { withStyles } from '@mui/styles';

//import { getConnectionInfo, getDeviceList } from './RestService';
//import { actUpdateUserDataThunk } from './actions';
//import { persistor } from './store';
import store from './store';

import './App.css';

import ConMainView from './containers/ConMainView';
import ConMainView2 from './containers/ConMainView2';
import ConSettings from './containers/ConSettings';
import ErrorBoundary from './containers/ErrorBoundary';
//import ConTVWidget from './containers/ConTVWidget';


const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        //backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        //width: 500,
        height: 450,
    },
});


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            count: 0,
            online: 0,  // 0: not determined, 1: online, -1: offline
        };
    }


    componentDidMount() {

        this.interval = setInterval(() => {
            
        }, 1000);

        //console.log('=============')
        //const { reducerKlines } = store.getState()
        //console.log(reducerKlines)
    }

    render() {
        return (
            <Router>
                <Routes>
                    <Route path="/:winId" element={<ConMainView2 />} />
                    <Route path="/settings/:winId" element={<ConSettings />} />
                </Routes>
            </Router>
        );
    }
}


App.propTypes = {
    //classes: PropTypes.object.isRequired,
};


function mapStateToProps(state) {
    return {
        /*
        userData: state.reducerUpdateUserData,
        requestStatus: state.reducerRequest,
        currentView: state.reducerUpdateCurrentView,
        getConnectionInfoStatus: state.reducerRequest[urlGetConnectionInfo],
        currentVersion: state.reducerUpdateCurrentVersion,
        language: state.reducerChangeLanguage,
        alertConfig: state.reducerAlertConfig,
        */
    };
}

export default connect(mapStateToProps)(App);
//export default connect(mapStateToProps)(withStyles(styles)(App));
