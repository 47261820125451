import * as actionType from '../actions/ActionType';


const reducerCoinList = (state='', action) => {

    let newState;
    switch (action.type) {

        case actionType.UPDATE_COINLIST:
        
            newState = action.msg
            return newState
        default:
            return state;
    }
}

export default reducerCoinList;
