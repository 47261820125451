export const CHANGE_LANGUAGE            = 'CHANGE_LANGUAGE'
export const UPDATE_CURRENT_VERSION     = 'UPDATE_CURRENT_VERSION'
export const REQUEST                    = 'REQUEST'
export const UPDATE_KLINES              = 'UPDATE_KLINES'
export const UPDATE_COINLIST            = 'UPDATE_COINLIST'
export const UPDATE_LIST_COIN           = 'UPDATE_LIST_COIN'
export const UPDATE_SETTINGS            = 'UPDATE_SETTINGS'
//export const DID_UPDATE_DEVICE_LIST     = 'DID_UPDATE_DEVICE_LIST'
//export const NOTIFY_NEW_VERSION         = 'NOTIFY_NEW_VERSION'
export const UPDATE_WINDOW_CONTENT      = 'UPDATE_WINDOW_CONTENT'
export const UPDATE_ERROR               = 'UPDATE_ERROR'