import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types';
import { format } from "date-fns";
//import { withStyles } from '@mui/styles';
import isElectron from 'is-electron';

import IconButton from '@mui/material/IconButton';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import OpenWith from '@mui/icons-material/OpenWith';
import Settings from '@mui/icons-material/Settings';

import InnerHTML from 'dangerously-set-html-content'

import '../App.css';
import store from '../store';
import { actUpdateWinContentThunk, actUpdateSettingsThunk } from '../actions';
import { ms } from 'date-fns/locale';


const styles = theme => ({
    root: {
        flexGrow: 1,
        //backgroundColor: '#FBFBFB',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        //color: 'red'
    },
});


const channel = new BroadcastChannel("redux_sync");


function ConMainView({ stateWinContent }) {

    var html = `
    <!-- TradingView Widget BEGIN -->
    <div class="tradingview-widget-container">
    <div class="tradingview-widget-container__widget"></div>
    <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/markets/" rel="noopener" target="_blank"><span class="blue-text">Markets today</span></a> by TradingView</div>
    <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-tickers.js" async>
    {
    "symbols": [
        {
        "description": "TSLA",
        "proName": "NASDAQ:TSLA"
        },
        {
        "description": "AAPL",
        "proName": "NASDAQ:AAPL"
        },
        {
        "description": "Gold",
        "proName": "OANDA:XAUUSD"
        },
        {
        "description": "OIL",
        "proName": "TVC:USOIL"
        }
    ],
    "colorTheme": "dark",
    "isTransparent": false,
    "showSymbolLogo": true,
    "locale": "en"
    }
    </script>
    </div>
    <!-- TradingView Widget END -->
    `

    //var winId = 1;
    let { winId } = useParams();
    const dispatch = useDispatch();
    const [ listOfCoin, setListOfCoin ] = useState('');
    const [ winContent, setWinContent ] = useState(html);
    const [ backgroundColor, setBackgroundColor ] = useState('#FFFFFF30');
    const [ textColor, setTextColor ] = useState('#FFFFFF');
    const [ layout, setLayout ] = useState('h');
    const [ showTime, setShowTime ] = useState(false);
    const [ borderRadius, setBorderRadius ] = useState(0);
    const [ isMouseOver, setIsMouseOver ] = useState(false);
    const [ lastTimeMouseOver, setLastTimeMouseOver ] = useState(new Date());


    /*
    const interval = setInterval(() => {

        const curTime = new Date()

        const diffTime = Math.abs(curTime - lastTimeMouseOver);
        console.log(diffTime + " milliseconds");

        if (diffTime > 3000) {

            setIsMouseOver(false)
        }
    }, 2000);
    */

    // Similar to componentDidMount and componentDidUpdate:
    /*
    useEffect(() => {

        console.log('===============')
        console.log(winId)
    });
    */


    useEffect(() => {

        console.log('* useEffect 0 *')
        console.log('Win ID: ', winId)

        setWinContent(html)
        
        if (stateWinContent) {
            if (stateWinContent[winId]) {

                setWinContent(stateWinContent[winId])
            }
        }


        // Handle Redux Sync message
        channel.onmessage = event => {

            const msg = event.data
            console.log(event.data);

            if (msg.name === 'winContent') {

                dispatch(actUpdateWinContentThunk(msg.winId, msg.winContent))
            } else if (msg.name === 'winContent') {

                dispatch(actUpdateSettingsThunk(msg.winId, { backgroundColor: msg.backgroundColor }))
            }
        };
    }, [])


    useEffect(() => {

        console.log('* useEffect 1 *')

        if (isElectron()) {

            const { ipcRenderer } = window.require('electron')

            ipcRenderer.on('settings-changed', (event, arg) => {
    
                console.log('[notif] settings-changed: ', arg)
    
                //this.setState({ listOfCoin: arg })
                //this.props.dispatch(actUpdateListCoin(arg))
    
                for (const [key, value] of Object.entries(arg)) {
    
                    if (key === 'listOfCoin') {
    
                        setListOfCoin(value)
                    } else if (key === 'winContent') {
    
                        setWinContent(value)
                    } else if (key === 'backgroundColor') {
    
                        setBackgroundColor(value)
                    } else if (key === 'textColor') {
    
                        setTextColor(value)
                    } else if (key === 'layout') {
    
                        setLayout(value)
                    } else if (key === 'showTime') {
    
                        setShowTime(value)
                    } else if (key === 'borderRadius') {
    
                        setBorderRadius(value )
                    }
                }
            })
    
            /*
            ipcRenderer.on('settings-window-closed', (event, arg) => {
        
                console.log('[notif] settings-window-closed: ', arg) // prints "pong"
    
                // Save settings info into redux
                this.props.dispatch(actUpdateSettingsThunk({
    
                    listOfCoin: this.state.listOfCoin,
                    textColor: this.state.textColor,
                    backgroundColor: this.state.backgroundColor,
                    opacity: this.state.opacity,
                    layout: this.state.layout,
                    showTime: this.state.showTime,
                    borderRadius: this.state.borderRadius,
                }))
    
                this.updateCoinList()
            })
            */
        } else {
            //const { ipcRenderer } = require('electron')
        }        
    }, [])


    useEffect(() => {

        //console.log('* useEffect 2 *')

        const interval = setInterval(() => {

            //console.log('===', lastTimeMouseOver)
            const curTime = new Date()

            const diffTime = Math.abs(curTime - lastTimeMouseOver);
            //console.log(diffTime + " milliseconds");
    
            if (diffTime > 5000) {
    
                //console.log('* over 5000 milliseconds')
                setIsMouseOver(false)
            }
        }, 1000);

            return () => clearInterval(interval);
    }, [lastTimeMouseOver]);

    
    function handleOptionBtn() {

        if (isElectron()) {

            const { ipcRenderer } = window.require('electron')
        
            //ipcRenderer.sendSync('synchronous-message', 'ping') // prints "pong"

            
            ipcRenderer.on('open-setting-window-reply', (event, arg) => {
        
                console.log('open-setting-window-reply: ', arg) // prints "pong"
            })

            ipcRenderer.send('open-setting-window', {cmd: 
                'open-setting-window', winId: winId})

            //this.props.dispatch(actUpdateListCoin({'key': 'hellocf sdff'}))
        }
    }


    function handleAddNewWindowBtn() {

        if (isElectron()) {

            const { ipcRenderer } = window.require('electron')
        
            //ipcRenderer.sendSync('synchronous-message', 'ping') // prints "pong"

            
            ipcRenderer.on('add-window-window-reply', (event, arg) => {
        
                console.log('add-window-window-reply: ', arg) // prints "pong"
            })

            ipcRenderer.send('add-window-window', {cmd: 
                'add-window-window', winId: winId})

            //this.props.dispatch(actUpdateListCoin({'key': 'hellocf sdff'}))
        }
    }


    function handleCloseWindowBtn() {

        if (isElectron()) {

            const { ipcRenderer } = window.require('electron')
        
            //ipcRenderer.sendSync('synchronous-message', 'ping') // prints "pong"

            
            ipcRenderer.on('close-window-reply', (event, arg) => {
        
                console.log('close-window-reply: ', arg) // prints "pong"
            })

            ipcRenderer.send('close-window', {cmd: 
                'close-window', winId: winId})

            //this.props.dispatch(actUpdateListCoin({'key': 'hellocf sdff'}))
        }
    }


    function handleOnMouseOver() {

        setIsMouseOver(true)

        const curTime = new Date()
        setLastTimeMouseOver(curTime)

        //console.log('mouse over')
        //console.log(lastTimeMouseOver)
    }


    return (

        <div style={{ display: 'flex', position: 'absolute', left: 0, top: 0,
                height: 'calc(100% - 1px)', width: 'calc(100% - 1px)', overflowY: 'hidden', 
                overflowX: 'hidden', flexDirection: 'row', backgroundColor: '#0000FF00',
                borderStyle: 'solid', 
                //borderRadius: this.state.borderRadius, 
                borderColor: 'red',
                //borderWidth: 0,  
                borderWidth: isMouseOver ? '1px' : '0px',
                justifyContent: 'stretch',
                //WebkitAppRegion: "drag",
                boxSizing: 'border-box',        // Used to set border of the dev tag...
                MozBoxSizing: 'border-box',     // ...go to inside...
                WebkitBoxSizing: 'border-box',  // ... instead of outside as default
                //borderRadius: 2
            }}
            //onMouseOver={() => setIsMouseOver(true)}
            //onMouseOut={() => setIsMouseOver(false)}
            //onMouseEnter={() => setIsMouseOver(true)}
            //onMouseLeave={() => setIsMouseOver(false)}
        >
            <div style={{ position: 'absolute', height: 28, width: 120, top: 0, left: 0, 
                    backgroundColor: isMouseOver ? '#FF0000E0' : '#FFFFFF00',
                    overflow: 'hidden',
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',
                    alignItems: 'center', //WebkitAppRegion: "drag",
                    borderRadius: 0,
                }}
                onMouseOver={() => handleOnMouseOver()}
                //onMouseEnter={() => setIsMouseOver(true)}
                //onMouseLeave={() => setIsMouseOver(false)}
            >
                <IconButton aria-label="fingerprint" style={{ height: 24, width: 28, WebkitAppRegion: "drag" }}
                    //onClick={() => handleOptionBtn()}
                >
                    <OpenWith style={{ cursor: 'move', height: 15, width: 15, 
                        color: isMouseOver ? '#FFFFFF' : '#FFFFFF00' 
                    }} />
                </IconButton>

                <div style={{ backgroundColor: isMouseOver ? '#FFFFFFA0' : '#FFFFFF00' , height: 18, width: 1 }} />
                <IconButton aria-label="fingerprint" style={{ height: 24, width: 28 }}
                    onClick={() => handleOptionBtn()}
                >
                    <Settings style={{ height: 16, width: 16, 
                        color: isMouseOver ? '#FFFFFF' : '#FFFFFF00' 
                    }} />
                </IconButton>

                <div style={{ backgroundColor: isMouseOver ? '#FFFFFFA0' : '#FFFFFF00' , height: 18, width: 1 }} />
                <IconButton aria-label="fingerprint" style={{ height: 24, width: 28 }}
                    onClick={() => handleAddNewWindowBtn()}
                >
                    <Add style={{ height: 20, width: 20, 
                        color: isMouseOver ? '#FFFFFF' : '#FFFFFF00' 
                    }} />
                </IconButton>

                <div style={{ backgroundColor: isMouseOver ? '#FFFFFFA0' : '#FFFFFF00' , height: 18, width: 1 }} />
                <IconButton aria-label="fingerprint" style={{ height: 24, width: 28 }}
                    onClick={() => handleCloseWindowBtn()}
                >
                    <Close style={{ height: 20, width: 20, 
                        color: isMouseOver ? '#FFFFFF' : '#FFFFFF00' 
                    }} />
                </IconButton>
            </div>

            <div style={{ flex: 1, backgroundColor: '#FFFFFF00', overflow: 'visible' }}>
                <InnerHTML style={{ width: 'calc(100% - 2px)', height: '100%' }} html={winContent} />
            </div>
        </div>
    );
}


ConMainView.propTypes = {
    //classes: PropTypes.object.isRequired,
};


function mapStateToProps(state) {
    return {
        language: state.reducerChangeLanguage,
        stateWinContent: state.reducerWindowContent,
        klines: state.reducerKlines,
        coinList: state.reducerCoinList,
        settings: state.reducerSettings,
    };
}


//export default connect(mapStateToProps)(withStyles(styles)(ConMainView));
export default connect(mapStateToProps)(ConMainView);
