import React from 'react';
import { useParams } from "react-router-dom";

import ErrorBoundary from './ErrorBoundary';
import ConMainView from './ConMainView';


function ConMainView2() {

    let { winId } = useParams();

    return (
        <ErrorBoundary winId={winId}>
            <ConMainView winId={winId}/>
        </ErrorBoundary>
    );
}

export default ConMainView2;
