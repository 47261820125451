import { createStore, applyMiddleware } from "redux";
import { createStateSyncMiddleware, initStateWithPrevTab, initMessageListener } from 'redux-state-sync';
import { PERSIST, PURGE, REHYDRATE } from 'redux-persist/es/constants';
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist';
//import createEncryptor from 'redux-persist-transform-encrypt'
import storage from 'redux-persist/lib/storage';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import bkiotApp from './reducers';
//import * as URL from './RequestUrl';
//import * as Bkiot from './bkiot';

/*
const encryptor = createEncryptor({
    secretKey: 'a87408bce88f90',
    onError: function(error) {

        console.log("Error in create Encryptor: ")
        console.log(error)
    }
})
*/


const persistConfig = {
    key: 'root',
    //storage: storage,
    storage,
    stateReconciler: autoMergeLevel1, // see "Merge Process" section for details.
    //blacklist: ['reducerErrors',],
    version: 0,
    //transforms: [encryptor],
};


const pReducer = persistReducer(
    persistConfig, 
    bkiotApp
);


const initialState = {

    //reducerUpdateDeviceList: [],
    //reducerUpdateDataStatusList: [],
    reducerRequest: {
        //mqtt_connection: { data: "", error: Bkiot.ErrorCode.success, loading: false, },
        //[URL.urlGetDeviceList]: { data: "", error: Bkiot.ErrorCode.success, loading: true, },
    },
    //reducerUpdateMqttMsg: {},
    //reducerUpdateMqttPkg: {},
    //reducerUpdateOutdoorAQI: {},
    /*
    reducerUpdateUserData: {
        token: "",
        dataStatus: -1,
        userId: -1,
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        nation: "",
        uuid4: "",
        web_push: {},
    },
    */
    /*
    reducerGetConnectionInfo: {
        region: "",
        mqttBroker: "",
        mqttBrokerPort: -1,
        mqtt_user: "",
        mqtt_pwd: "",
    },
    */
    //reducerUpdateCurrentView: {},
    //reducerCallServerRequest: [],   // ex: {api: "getDeviceList", param1: "value1",...}
    //reducerUpdateCurrentVersion: {ver: '0.0.1'},
    //reducerChangeLanguage: 0,       // 0: Vietnamese, 1: English
    //reducerDidUpdateDeviceList: false,
    //reducerTest: 1
}


//const store = createStore(bkiotApp, initialState, applyMiddleware(thunk));

const middlewares = [thunk];
//const middlewares = [createStateSyncMiddleware(persistConfig), thunk];
//const middlewares = [createStateSyncMiddleware({blacklist: [PERSIST, REHYDRATE]}), thunk];
const store = createStore(pReducer, initialState, applyMiddleware(...middlewares));
// init state with other tabs
//initStateWithPrevTab(store);
//initMessageListener(store);

export const persistor = persistStore(store);

export default store;
