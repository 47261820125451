import * as actionType from '../actions/ActionType';

const reducerSettings = (state={}, action) => {

    let newState;
    switch (action.type) {
        case actionType.UPDATE_SETTINGS:
        
            newState = action.msg
            return newState
        default:
            return state;
    }
}

export default reducerSettings;
