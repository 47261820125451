import * as actionType from '../actions/ActionType';


const reducerRequest = (state={}, action) => {

    let newState;
    switch (action.type) {
        case actionType.REQUEST:
        
            newState = action.request
            return newState
        default:
            return state;
    }
}


export default reducerRequest;
