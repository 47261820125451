import { combineReducers } from 'redux'
import { withReduxStateSync } from 'redux-state-sync';
import reducerRequest from './reducerRequest'
import reducerKlines from './reducerKlines'
import reducerCoinList from './reducerCoinList'
import reducerListCoin from './reducerListCoin'
import reducerSettings from './reducerSettings'
import reducerErrors from './reducerErrors'
import reducerWindowContent from './reducerWindowContent'


const bkiotApp = combineReducers({
    reducerRequest,
    reducerKlines,
    reducerCoinList,
    reducerListCoin,
    reducerSettings,
    reducerErrors,
    reducerWindowContent
})

//export default bkiotApp;
export default withReduxStateSync(bkiotApp);
