import * as actionType from './ActionType';
import store from '../store';
import mergeByKey from "array-merge-by-key";


//
export const actChangeLanguage = (languageCode) => ({

    type: actionType.CHANGE_LANGUAGE,
    data: languageCode,
});


//
export const actChangeLanguageThunk = () => {

    return function(dispatch, getState) {

        // Get the current language
        const { reducerChangeLanguage } = store.getState()
        var newLangugage = reducerChangeLanguage
        
        if (newLangugage === 0) {

            newLangugage = 1
        } else {

            newLangugage = 0
        }

        // Update to new language
        store.dispatch(actChangeLanguage(newLangugage))
    }
};


//
export const actUpdateRequest = (request) => ({

    type: actionType.REQUEST,
    request: request,
});

export const actUpdateRequestThunk = (data, loading, error, key) => {

    return function(dispatch, getState) {

        const { reducerRequest } = store.getState()
        var newObject = JSON.parse(JSON.stringify(reducerRequest));
        newObject[key] = {"data": data, "loading": loading, "error": error}
        store.dispatch(actUpdateRequest(newObject))
    };
};


//
export const actUpdateKlines = (klines) => ({

    type: actionType.UPDATE_KLINES,
    msg: klines,
})


export const actUpdateKlinesThunk = (kline) => {

    return function(dispatch, getState) {

        // Get current state
        var { reducerKlines } = getState()
        
        if (!reducerKlines) {

            reducerKlines = {}
        }

        // Deep copy the current state
        var newObject = JSON.parse(JSON.stringify(reducerKlines));

        // Update the new kline into the state
        const streamName = kline['stream']

        if (streamName) {

            newObject[streamName] = kline

            // Save to the store
            dispatch(actUpdateKlines(newObject))
        }
    }
}


//
export const actUpdateWinContent = (windowContent) => ({

    type: actionType.UPDATE_WINDOW_CONTENT,
    msg: windowContent,
})


export const actUpdateWinContentThunk = (windowId, content) => {

    return function(dispatch, getState) {

        // Get current state
        var { reducerWindowContent } = getState()
        
        if (!reducerWindowContent) {

            reducerWindowContent = {}
        }

        // Deep copy the current state
        var newObject = JSON.parse(JSON.stringify(reducerWindowContent));
        // Update the new window content into the state
        newObject[windowId] = content

        // Save to the store
        dispatch(actUpdateWinContent(newObject))
    }
}


//
export const actUpdateSettings = (settings) => ({

    type: actionType.UPDATE_SETTINGS,
    msg: settings,
})


export const actUpdateSettingsThunk = (windowId, settings) => {

    return function(dispatch, getState) {

        // Get current state
        var { reducerSettings } = getState()
        
        if (!reducerSettings) {

            reducerSettings = {}
        }

        // Deep copy the current state
        var newObject = JSON.parse(JSON.stringify(reducerSettings));

        for (const [key, value] of Object.entries(settings)) {
            
            if (!newObject[windowId]) {

                newObject[windowId] = {}
            }
            newObject[windowId][key] = value
            //console.log(`${key}: ${value}`);
        }

        // Save to the store
        dispatch(actUpdateSettings(newObject))
    }
}


//
export const actUpdateErrors = (errors) => ({

    type: actionType.UPDATE_ERROR,
    msg: errors,
})


export const actUpdateErrorsThunk = (errors) => {

    return function(dispatch, getState) {

        // Get current state
        var { reducerErrors } = getState()

        if (!reducerErrors) {

            reducerErrors = {}
        }

        // Deep copy the current state
        var newObject = JSON.parse(JSON.stringify(reducerErrors));

        for (const [key, value] of Object.entries(errors)) {

            newObject[key] = value
        }

        // Save to the store
        dispatch(actUpdateErrors(newObject))
    }
}


//
export const actUpdateCoinList = (coinList) => ({

    type: actionType.UPDATE_COINLIST,
    msg: coinList,
})


//
export const actUpdateListCoin = (listCoin) => ({

    type: actionType.UPDATE_LIST_COIN,
    data: listCoin,
});


//
export const actUpdateListCoinThunk = (listCoin) => {

    return function(dispatch, getState) {

        // Update to new language
        store.dispatch(actChangeLanguage(listCoin))
    }
};