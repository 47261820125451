import { Component } from "react";
import { connect } from 'react-redux'

import store from '../store';
import { actUpdateErrorsThunk } from '../actions';


class ErrorBoundary extends Component {

    constructor(props) {
        super(props);

        const winId = this.props.winId
        this.state = { hasError: false, winId: winId };
    }
 

    static getDerivedStateFromError(error) {

        console.log('*** WinContent Error ***')
        store.dispatch(actUpdateErrorsThunk({winContent: 'invalid'}))

        // Update state so the next render will show the fallback UI.
        return {
            hasError: true,
            error,
        };
    }


    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service like AppSignal
        // logErrorToMyService(error, errorInfo);
    }


    render() {
        const { hasError, error } = this.state;

        if (hasError) {
            // You can render any custom fallback UI
            return (
                <div>
                    <p>Something went wrong, Please Restart the app!</p>
                    {/*
                        {error.message && <span>Here's the error: {error.message}</span>}
                    */}
                </div>
            );
        }

        return this.props.children;
    }
}
 

ErrorBoundary.propTypes = {
    //classes: PropTypes.object.isRequired,
};


function mapStateToProps(state) {
    return {
        language: state.reducerChangeLanguage,
        errors: state.reducerErrors,
    };
}

export default connect(mapStateToProps)(ErrorBoundary);
