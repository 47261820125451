import * as actionType from '../actions/ActionType';

const reducerErrors = (state={}, action) => {

    let newState;
    switch (action.type) {
        case actionType.UPDATE_ERROR:
        
            newState = action.msg
            return newState
        default:
            return state;
    }
}

export default reducerErrors;
