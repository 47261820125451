import React, { Component } from 'react';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Popover from '@mui/material/Popover';

import Apps from '@mui/icons-material/Apps';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import Save from '@mui/icons-material/Save';
import MoreVert from '@mui/icons-material/MoreVert';
import Settings from '@mui/icons-material/Settings';
//import { ColorPicker, createColor } from "material-ui-color";

import moment from 'moment';
import { SketchPicker } from 'react-color';

import isElectron from 'is-electron';

import { actUpdateWinContentThunk, actUpdateErrorsThunk, actUpdateSettings, actUpdateSettingsThunk } from '../actions';

import '../App.css';
import store from '../store';
import { height } from '@mui/system';
import { RemoveScrollBar } from 'react-remove-scroll-bar';
import ConColorPicker from './ConColorPicker';

//const log = require('electron-log');


const styles = theme => ({
    root: {
        flexGrow: 1,
        //backgroundColor: '#FBFBFB',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        //color: 'red'
    },
    contentDiv: {
        //height: calc(100% - 60)
    },
    colorPicker: {
        height: '10px',
        textColor: '#FFFFFF'
    }
});


const channel = new BroadcastChannel("redux_sync");


function withParams(Component) {
    return (props) => <Component {...props} params={useParams()} />;
}


class ConSettings extends React.Component {

    constructor(props) {
        super(props);

        const { winId } = this.props.params
        console.log('Win ID: ', winId)

        this.state = {
            winId: winId,
            winContent: '',
            textColor: '#FFFFFF',
            backgroundColor: '#000000',
            opacity: 0.5,
            layout: 'vertical',     // horizontal
            showTime: false,
            borderRadius: 0,
            lastTimeChangeWinContent: null,
            displayColorPicker: false,
        }
    }


    componentDidMount() {

        // Load setting info
        const { settings, winContent } = this.props

        // Clear the error
        this.props.dispatch(actUpdateErrorsThunk({winContent: ''}))


        // Load content for the windows
        if (winContent?.[this.state.winId]) {

            this.setState({ winContent: winContent?.[this.state.winId] })
        }

        // Load other settings
        if (settings) {

            if (settings[this.state.winId]?.listOfCoin) {

                this.setState({ listOfCoin: settings[this.state.winId]?.listOfCoin })
            }

            if (settings[this.state.winId]?.backgroundColor) {

                this.setState({ backgroundColor: settings[this.state.winId]?.backgroundColor })
            }

            if (settings[this.state.winId]?.textColor) {

                this.setState({ textColor: settings[this.state.winId]?.textColor })
            }

            if (settings[this.state.winId]?.layout) {

                this.setState({ layout: settings[this.state.winId]?.layout })
            }

            if (settings[this.state.winId]?.showTime) {

                this.setState({ showTime: settings[this.state.winId]?.showTime })
            }

            if (settings[this.state.winId]?.borderRadius) {

                this.setState({ borderRadius: settings[this.state.winId]?.borderRadius })
            }
        }


        // Create timer to save the window web conent after changing a bout 2 seconds
        this.interval = setInterval(() => {

            const { errors } = this.props
            //console.log('ERROR:')
            //console.log(errors)

            if (this.state.lastTimeChangeWinContent) {

                console.log('last time: ', this.state.lastTimeChangeWinContent)
                const curTime = new Date()

                const diffTime = Math.abs(curTime - this.state.lastTimeChangeWinContent);
        
                if (diffTime > 2000) {

                    console.log('last time < 2000: ', this.state.lastTimeChangeWinContent)

                    if (errors['winContent'] && (errors['winContent'] != '')) {
                        // Relaunch the app
                        console.log('Do nothing')
                    } else {

                        console.log('It is time to saving')
                        // Save the new window content if there isn't error
                        this.props.dispatch(actUpdateWinContentThunk(this.state.winId, this.state.winContent))
                        channel.postMessage({name: 'winContent', winId: this.state.winId, winContent: this.state.winContent});

                        // Clear the time
                        this.setState({ lastTimeChangeWinContent: null })
                    }
                }
            }
        }, 2000);
    }


    componentWillUnmount() {
        clearInterval(this.interval);
    }


    handleWinContentChanged = (event) => {

        console.log(event.target.value)

        // Update window content and the changing time
        this.setState({ winContent: event.target.value, lastTimeChangeWinContent: new Date() })
        //this.props.dispatch(actUpdateWinContentThunk(this.state.winId, event.target.value))

        // Send new coin list to the main window to save to redux
        if (isElectron()) {
            const { ipcRenderer } = window.require('electron')
            ipcRenderer.send('settings-changed', {winId: this.state.winId, winContent: event.target.value})
        } else {
            const { ipcRenderer } = require('electron')
            ipcRenderer.send('settings-changed', {winId: this.state.winId, winContent: event.target.value})
        }
    }


    handleBGColorChanged = (color) => {

        const newColor = color['hex']
        //console.log(color)
        this.setState({ backgroundColor: color['rgb'] })
        this.props.dispatch(actUpdateSettingsThunk(this.state.winId.toString(), 
            { backgroundColor: color['rgb'] }))

        channel.postMessage({ name: 'backgroundColor', winId: this.state.winId, backgroundColor: color['rgb'] });

        if (isElectron()) {
            const { ipcRenderer } = window.require('electron')
            ipcRenderer.send('settings-changed', {winId: this.state.winId, backgroundColor: newColor})
        } else {
            const { ipcRenderer } = require('electron')
            ipcRenderer.send('settings-changed', {winId: this.state.winId, backgroundColor: newColor})
        }
    }


    handleTextColorChanged = (color) => {

        const newColor = color['hex']
        console.log(newColor)
        this.setState({ textColor: newColor })

        if (isElectron()) {
            const { ipcRenderer } = window.require('electron')
            ipcRenderer.send('settings-changed', {winId: this.state.winId, textColor: newColor})     
        } else {
            const { ipcRenderer } = require('electron')
            ipcRenderer.send('settings-changed', {winId: this.state.winId, textColor: newColor})
        }
    }


    handleLayoutChanged = (layout) => {

        this.setState({ layout: layout })

        if (isElectron()) {
            const { ipcRenderer } = window.require('electron')
            ipcRenderer.send('settings-changed', {winId: this.state.winId, layout: layout})
        } else {
            const { ipcRenderer } = require('electron')
            ipcRenderer.send('settings-changed', {winId: this.state.winId, layout: layout})
        }
    }


    handleShowTimeChanged = (value) => {

        this.setState({ showTime: value })

        if (isElectron()) {
            const { ipcRenderer } = window.require('electron')
            ipcRenderer.send('settings-changed', {winId: this.state.winId, showTime: value})
        } else {
            const { ipcRenderer } = require('electron')
            ipcRenderer.send('settings-changed', {winId: this.state.winId, showTime: value})
        }
    }


    handleBorderRadiusChanged = (event, value) => {

        this.setState({ borderRadius: value })

        if (isElectron()) {
            const { ipcRenderer } = window.require('electron')
            ipcRenderer.send('settings-changed', {winId: this.state.winId, borderRadius: value})
        } else {
            const { ipcRenderer } = require('electron')
            ipcRenderer.send('settings-changed', {winId: this.state.winId, borderRadius: value})
        }
    }


    handleGotoWebSite = (event, value) => {

        if (isElectron()) {
            const { ipcRenderer } = window.require('electron')
            ipcRenderer.send('goto-website', {foo: 'bar'})
        } else {
            const { ipcRenderer } = require('electron')
            ipcRenderer.send('goto-website', {foo: 'bar'})
        }
    }


    handleExitApp = (event, value) => {

        if (isElectron()) {
            const { ipcRenderer } = window.require('electron')
            ipcRenderer.send('exit-app', {foo: 'bar'})
        } else {
            const { ipcRenderer } = require('electron')
            ipcRenderer.send('exit-app', {foo: 'bar'})
        }
    }


    handleOpenColorPicker = () => {

        console.log('*** handleOpenColorPicker ***')
        this.setState({ displayColorPicker : !this.state.displayColorPicker })
    }


    render() {
        const { classes } = this.props

        return (

            <div style={{ display: 'flex', position: 'absolute', height: '100%', width: '100%', overflowY: 'hidden', overflowX: 'hidden',
                justifyContent: 'center', alignItems: 'center',
                backgroundColor: '#FFFF0000', flexDirection: 'column', borderWidth: 0, borderStyle: 'solid', borderRadius: 0
            }}>
                <div style={{ backgroundColor: 'white', height: 'calc(100% - 20px)',  width: 'calc(100% - 20px)' }}>
                    
                    <TextField
                        style={{ width: '100%', marginTop: 10 }}
                        id="list_of_coin"
                        variant='outlined'
                        label="Paste Widget Code Here"
                        multiline
                        //maxRows={5}
                        rows={29}
                        value={this.state.winContent}
                        //onChange={(event) => this.handleCoinListChanged(event)}
                        onChange={this.handleWinContentChanged}
                    />

                    {/*
                    <div style={{ width: '100%', height: 60, display: 'flex', flexDirection: 'row',
                        justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#00000010',
                        marginTop: 10, borderRadius: 6
                    }}>
                        <label style={{ marginLeft: 10 }}>{'Background Color:'}</label>
                        <ConColorPicker 
                            color={this.state.backgroundColor}
                            pickBackgroundColor={this.handleBGColorChanged}
                        />
                    </div>
                    */}

                    {/*
                    <div style={{ width: '100%', height: 60, display: 'flex', flexDirection: 'row',
                        justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#00000010',
                        marginTop: 10, borderRadius: 6
                    }}>
                        <label style={{ marginLeft: 10 }}>{'Text Color:'}</label>
                        <div style={{ width: 130, marginRight: 10, overflow: 'hidden', backgroundColorr: 'red' }}>
                            
                        </div>
                    </div>
                    */}


                    {/*
                    <div style={{ width: '100%', height: 60, display: 'flex', flexDirection: 'row',
                        justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#00000010',
                        marginTop: 10, borderRadius: 6
                    }}>
                        <label style={{ marginLeft: 10 }}>{'Show Time:'}</label>

                        <div>
                            <label style={{ marginRight: 10, fontWeight: (this.state.showTime ? 'bold' : 'normal'),
                                textDecoration: (this.state.showTime ? 'underline' : '') }}
                                onClick={() => this.handleShowTimeChanged(true)}
                            >
                                {'On'}
                            </label>
                            
                            <label style={{ fontWeight: (!this.state.showTime ? 'bold' : 'normal'), marginRight: 10,
                                textDecoration: (!this.state.showTime ? 'underline' : '') }}
                                onClick={() => this.handleShowTimeChanged(false)}
                            >
                                {'Off'}
                            </label>
                        </div>
                    </div>
                    */}

                    {/*
                    <div style={{ width: '100%', height: 60, display: 'flex', flexDirection: 'row',
                        justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#00000010',
                        marginTop: 10, borderRadius: 6
                    }}>
                        <label style={{ marginLeft: 10 }}>{'Rounded Border:'}</label>

                        <div style={{ height: '100%', width: 200, display: 'flex', justifyContent: 'center', 
                            alignItems: 'center', marginRight: 10,
                        }}>
                            <Slider
                                size="small"
                                //defaultValue={this.state.borderRadius}
                                value={this.state.borderRadius}
                                aria-label="Small"
                                valueLabelDisplay="auto"
                                step={1}
                                min={0}
                                max={100}
                                onChange={this.handleBorderRadiusChanged}
                            />
                        </div>
                    </div>
                    */}

                    <div style={{ position: 'absolute', bottom: 0, width: 'calc(100% - 20px)', height: 40, 
                        backgroundColor: '#FFFF0000', display: 'flex', justifyContent: 'center', 
                        alignItems: 'center'
                    }}>
                        <label style={{ color: 'gray', fontWeight: 'bold' }}>
                            {'Version 1.1'}
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}


ConSettings.propTypes = {
    classes: PropTypes.object.isRequired,
};


function mapStateToProps(state) {
    return {
        language: state.reducerChangeLanguage,
        winContent: state.reducerWindowContent,
        coinList: state.reducerCoinList,
        klines: state.reducerKlines,
        settings: state.reducerSettings,
        errors: state.reducerErrors
    };  
}


//export default connect(mapStateToProps)(ConSettings);
export default connect(mapStateToProps)(withParams(ConSettings));
